import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { downloadSocietyIndexPDF } from "./actionCreator";

export default function PdfComponent(props) {
  let componentRef = useRef();
  let printBtnRef = useRef();
  const { lastAPIParams } = props;
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (reportData.length > 0) {
      setTimeout(() => {
        printBtnRef.current.click();
      }, 100);
    }
    }, [reportData]);
  
  const myFunction = async () => {
    const queryParams = {
      ...lastAPIParams
    };
    const response = await downloadSocietyIndexPDF(queryParams);
    if (response.data) {
      setReportData(response.data);
      console.log(response.data);
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      <div>
        <button style={{ display: "none" }} ref={printBtnRef} onClick={handlePrint}>Print</button>
        <div>
          <button
            className="bg-[#007AFF] text-white px-3 py-2 rounded-[5px] ml-2"
            onClick={() => myFunction()}
          >
            Download Full PDF
          </button>
        </div>

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <div ref={componentRef}>
            {reportData.map((data) => (
              <div key={data._id} className="m-2 mb-4">
                <div className="mb-1">
                  {data.societyIndex} - {data.society || "N/A"}, {data.streetName || "N/A"}, {data.city || "N/A"}
                </div>

                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1">SMV No</th>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1">Home/Flat No</th>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1">Name</th>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1">Mobile</th>
                      <th class="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1">Village</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.users.map((member) => (
                      <tr key={member?._id} className='text-center p-2'>
                        <td className="p-2 border-b border-blue-gray-50">{member.SMVNo}</td>
                        <td className="p-2 border-b border-blue-gray-50">{member?.address?.blockNo}</td>
                        <td className="p-2 border-b border-blue-gray-50">{member.firstName} {member.middleName} {member.lastName}</td>
                        <td className="p-2 border-b border-blue-gray-50">{member.mobile}</td>
                        <td className="p-2 border-b border-blue-gray-50">{member.village}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}