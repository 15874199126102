import React, { Fragment, useState } from "react";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import TextField from "../Common/Input/TextField";

const LocationAddModel = (props) => {
    const { closeMemeberModel, addLocation, open, currentSociety } = props;
    const [location, setLocation] = useState(currentSociety?.location || '');
    const handleChange = (value) => {
        setLocation(value);
    }

    return (
        <>
            <Transition appear show={open} as={Fragment}>
                <Dialog as="div" className="relative z-40" open={open} onClose={() => { }}>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/50" />
                    </TransitionChild>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <TransitionChild
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="mt-2">
                                        <div className="grid gap-4">
                                        <TextField
                                                    id="location"
                                                    type="text"
                                                    className="placeholder:gray100"
                                                    label="Society Location"
                                                    required={true}
                                                    placeholder="Add Location"
                                                    value={location}
                                                    onChange={(e) => handleChange(e.target.value)}
                                                />
                                        </div>
                                    </div>

                                    <div className="mt-4 flex justify-end">
                                        <button
                                            type="button"
                                            className="mr-3 inline-flex justify-center rounded-md border bg-white hover:bg-red-50 border-orange-900 px-4 py-2 text-sm font-medium text-orange-900  focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeMemeberModel}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => addLocation(location)}
                                            className="inline-flex justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-sm font-medium text-white hover:bg-orange-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                        >
                                            Update Details
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </TransitionChild>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default LocationAddModel;
