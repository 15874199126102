import React from "react";
import { useRoutes } from "react-router-dom";
import { RequestData } from "../../../Components";

export default function RequestRouter() {
  const RequestRoute = [
    {
      path: "request",
      element: (
        <>
          <RequestData />
        </>
      ),
    },
  ];

  const RequestRouter = useRoutes(RequestRoute);
  return RequestRouter;
}
