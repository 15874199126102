import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

const ShowHistoryOfRequest = ({ openHistory, setOpenHistory }) => {
    // Static chat data
    const chatHistory = [
        {
            sender: "User",
            subject: "Change Profile Pic",
            discription: "Hello, I want to Change My Profile Pic. I attach the pic below this message.",
            time: "10:00 AM",
            date: "2024-11-25",
        },
        {
            sender: "Admin",
            discription: "Sure, You can check your profile pic after some time.",
            time: "10:05 AM",
            date: "2024-11-25",
        },
        {
            sender: "User",
            subject: "Change Address",
            discription: "I want to Change my Address. Because I shift to new house.",
            time: "10:10 AM",
            date: "2024-11-25",
        },
        {
            sender: "Admin",
            discription: "Please Give Your Updated Address.",
            time: "10:15 AM",
            date: "2024-11-25",
        },
    ];

    return (
        <>
            <Transition appear show={openHistory} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-2xl h-[90vh] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className="flex justify-between pb-4 border-b border-gray-400">
                                        <div className="text-lg font-semibold text-orange-600">
                                            Request History
                                        </div>
                                        <Icon
                                            path={mdiClose}
                                            size={1}
                                            className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                                            onClick={() => {
                                                setOpenHistory(false);
                                            }}
                                        />
                                    </div>
                                    <div className="p-6 space-y-4 max-h-[90%] overflow-y-auto">
                                        {chatHistory.map((chat, index) => (
                                            <div
                                                key={index}
                                                className={`flex ${chat.sender === "Admin"
                                                        ? "justify-end"
                                                        : "justify-start"
                                                    }`}
                                            >
                                                <div
                                                    className={`${chat.sender === "Admin"
                                                            ? "bg-blue-500 text-white"
                                                            : "bg-gray-100 text-gray-900"
                                                        } p-4 rounded-lg shadow-md max-w-xs`}
                                                >
                                                    <p className="text-sm font-medium mb-1">
                                                        {chat.sender}
                                                    </p>
                                                    {
                                                        chat.sender === "User" ?
                                                            <p className="text-sm">
                                                               <p className="font-bold">Subject : </p> 
                                                                {chat.subject}
                                                            </p>
                                                            :
                                                            <></>
                                                    }
                                                    <p className="text-sm">{chat.discription}</p>
                                                    <div className={`text-xs mt-2 ${chat.sender === "Admin" ? "text-black" : "text-gray-500"}`}>
                                                        {chat.date}, {chat.time}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default ShowHistoryOfRequest;
