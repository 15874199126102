export const PlaylistData = [
    {
        id: 1,
        thumbnailURl: "/assets/image/thumbnail.jpg",
        title: "Ram Katha",
        vakta: "Achalswarupdasji swami",
        category: "katha",
        counts: '7',
        visiblity: "visible",
    },
    {
        id: 2,
        thumbnailURl: "/assets/image/thumbnail.jpg",
        title: "purushotam charitra pushpmala",
        vakta: "Harinarayandasji swami",
        category: "katha",
        counts: '559',
        visiblity: "visible",
    },
]

export const VideoList = [
    {
        id: 1,
        date: '20 Jan 2024',
        title: 'Ram Katha',
        vakta: 'Achalswarupdasji swami',
        thumbnailURl: "/assets/image/thumbnail.jpg",
    },
    {
        id: 2,
        date: '21 Jan 2024',
        title: 'Ram Katha',
        vakta: 'Achalswarupdasji swami',
        thumbnailURl: "/assets/image/thumbnail.jpg",
    },
    {
        id: 3,
        date: '22 Jan 2024',
        title: 'Ram Katha',
        vakta: 'Achalswarupdasji swami',
        thumbnailURl: "/assets/image/thumbnail.jpg",
    },
    {
        id: 4,
        date: '23 Jan 2024',
        title: 'Ram Katha',
        vakta: 'Achalswarupdasji swami',
        thumbnailURl: "/assets/image/thumbnail.jpg",
    },
    {
        id: 5,
        date: '24 Jan 2024',
        title: 'Ram Katha',
        vakta: 'Achalswarupdasji swami',
        thumbnailURl: "/assets/image/thumbnail.jpg",
    },
    {
        id: 6,
        date: '25 Jan 2024',
        title: 'Ram Katha',
        vakta: 'Achalswarupdasji swami',
        thumbnailURl: "/assets/image/thumbnail.jpg",
    },
    {
        id: 7,
        date: '26 Jan 2024',
        title: 'Ram Katha',
        vakta: 'Achalswarupdasji swami',
        thumbnailURl: "/assets/image/thumbnail.jpg",
    },
]

export const PlaylistMokeData = [
    {
        id: 1,
        thumbnailURl: "/assets/image/event-1.webp",
        category: "katha",
        title: "Shree Purushottam Charitra Pushpmala",
        totalVideos: "459",
        videos: [
            {
                id: 1,
                title: "part 1 - shri purushottam charitra pushpmala",
                thumbnailURl: "/assets/image/event-1.webp"
            },
            {
                id: 2,
                title: "part 2 - shri purushottam charitra pushpmala",
                thumbnailURl: "/assets/image/event-1.webp"
            },
            {
                id: 3,
                title: "part 3 - shri purushottam charitra pushpmala",
                thumbnailURl: "/assets/image/event-1.webp"
            },
            {
                id: 4,
                title: "part 4 - shri purushottam charitra pushpmala",
                thumbnailURl: "/assets/image/event-1.webp"
            },
            {
                id: 5,
                title: "part 5 - shri purushottam charitra pushpmala",
                thumbnailURl: "/assets/image/event-1.webp"
            },
            {
                id: 6,
                title: "part 6 - shri purushottam charitra pushpmala",
                thumbnailURl: "/assets/image/event-1.webp"
            },
            {
                id: 7,
                title: "part 7 - shri purushottam charitra pushpmala",
                thumbnailURl: "/assets/image/event-1.webp"
            },
            {
                id: 8,
                title: "part 8 - shri purushottam charitra pushpmala",
                thumbnailURl: "/assets/image/event-1.webp"
            },
            {
                id: 9,
                title: "part 9 - shri purushottam charitra pushpmala",
                thumbnailURl: "/assets/image/event-1.webp"
            },
            {
                id: 10,
                title: "part 10 - shri purushottam charitra pushpmala",
                thumbnailURl: "/assets/image/event-1.webp"
            },
        ]
    },
    {
        id: 2,
        thumbnailURl: "",
        category: "katha",
        title: "Shri Ram katha",
        totalVideos: "125",
        videos: [
            {
                id: 1,
                title: "part 1 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 2,
                title: "part 2 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 3,
                title: "part 3 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 4,
                title: "part 4 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 5,
                title: "part 5 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 6,
                title: "part 6 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 7,
                title: "part 7 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 8,
                title: "part 8 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 9,
                title: "part 9 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 10,
                title: "part 10 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
        ]
    },
    {
        id: 3,
        thumbnailURl: "/assets/image/event-3.webp",
        category: "katha",
        title: "Dhanurmas Gyan Yagna",
        totalVideos: "389",
        videos: [
            {
                id: 1,
                title: "part 1 - dhanurmas katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 2,
                title: "part 2 - dhanurmas katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 3,
                title: "part 3 - dhanurmas katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 4,
                title: "part 4 - dhanurmas katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 5,
                title: "part 5 - dhanurmas katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 6,
                title: "part 6 - dhanurmas katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 7,
                title: "part 7 - dhanurmas katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 8,
                title: "part 8 - dhanurmas katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 9,
                title: "part 9 - dhanurmas katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 10,
                title: "part 10 - dhanurmas katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
        ]
    },
    {
        id: 4,
        thumbnailURl: "/assets/image/event-4.jpg",
        category: "kirtan",
        title: "Dhyan na pado",
        totalVideos: "196",
        videos: [
            {
                id: 1,
                title: "part 1 - dhyan na pado",
                thumbnailURl: "/assets/image/event-4.jpg"
            },
            {
                id: 2,
                title: "part 2 - dhyan na pado",
                thumbnailURl: "/assets/image/event-4.jpg"
            },
            {
                id: 3,
                title: "part 3 - dhyan na pado",
                thumbnailURl: "/assets/image/event-4.jpg"
            },
            {
                id: 4,
                title: "part 4 - dhyan na pado",
                thumbnailURl: "/assets/image/event-4.jpg"
            },
            {
                id: 5,
                title: "part 5 - dhyan na pado",
                thumbnailURl: "/assets/image/event-4.jpg"
            },
            {
                id: 6,
                title: "part 6 - dhyan na pado",
                thumbnailURl: "/assets/image/event-4.jpg"
            },
            {
                id: 7,
                title: "part 7 - dhyan na pado",
                thumbnailURl: "/assets/image/event-4.jpg"
            },
            {
                id: 8,
                title: "part 8 - dhyan na pado",
                thumbnailURl: "/assets/image/event-4.jpg"
            },
            {
                id: 9,
                title: "part 9 - dhyan na pado",
                thumbnailURl: "/assets/image/event-4.jpg"
            },
            {
                id: 10,
                title: "part 10 - dhyan na pado",
                thumbnailURl: "/assets/image/event-4.jpg"
            },
        ]
    },
    {
        id: 5,
        thumbnailURl: "/assets/image/event-2.jpg",
        category: "katha",
        title: "Shri Ram katha",
        totalVideos: "125",
        videos: [
            {
                id: 1,
                title: "part 1 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 2,
                title: "part 2 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 3,
                title: "part 3 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 4,
                title: "part 4 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 5,
                title: "part 5 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 6,
                title: "part 6 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 7,
                title: "part 7 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 8,
                title: "part 8 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 9,
                title: "part 9 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
            {
                id: 10,
                title: "part 10 - shri ram katha",
                thumbnailURl: "/assets/image/event-2.jpg"
            },
        ]
    },
]

export const SevaListData = [
    {
        _id: "66e5d1248f5ef7356720e372",
        seva: "Vachnamrut Dwishtabdi Mahotsav",
        imageURL: "Book/2024_09_14/DailyDarshan-46155098.webp",
        bookURL: "https://www.swaminarayansurat.com/test/books/Vachnamrut__Vedroad.pdf",
        enabled: true,
        isDeleted: false,
        order: 1,

    }
]

export const SevaDetail = [
    {
        _id: "67111e15a615e112f3b55009",
        name: "John Smith",
        sevaId: "66e5d1248f5ef7356720e372",
        hasContent: true,
        order: 0,
        isDeleted: false,
        createdAt: "2024-10-17T14:24:21.511Z",
        updatedAt: "2024-10-18T05:09:26.968Z",
        __v: 0
    },
    {
        _id: "671162eb68a217c4ce96a3ae",
        name: "Michael Johnson",
        sevaId: "66e5d1248f5ef7356720e372",
        hasContent: false,
        order: 1,
        isDeleted: false,
        createdAt: "2024-10-17T19:18:03.834Z",
        updatedAt: "2024-10-17T20:02:32.486Z",
        __v: 0
    },
    {
        _id: "6711629f68a217c4ce96a380",
        name: "David Williams",
        sevaId: "66e5d1248f5ef7356720e372",
        hasContent: false,
        order: 2,
        isDeleted: false,
        createdAt: "2024-10-17T19:16:47.598Z",
        updatedAt: "2024-10-17T20:02:32.493Z",
        __v: 0
    },
    {
        _id: "671168e2e92947f933e46e74",
        name: "Robert Brown",
        sevaId: "66e5d1248f5ef7356720e372",
        hasContent: false,
        order: 3,
        isDeleted: false,
        createdAt: "2024-10-17T19:43:30.809Z",
        updatedAt: "2024-10-17T20:02:32.499Z",
        __v: 0
    }
]