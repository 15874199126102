import React, { useEffect, useState } from 'react'

import {
  createColumnHelper,
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  FilterFns,
} from '@tanstack/react-table'
import { mdiEye } from '@mdi/js';
import {
  RankingInfo,
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils';

import { Icon } from "@iconify/react";
import ShowHistoryOfRequest from './ShowHistoryOfRequest';

import { getAllPendingRequests } from './actionCreator';
import moment from 'moment';
import { toastr } from '../../services/common.service';

const fuzzyFilter = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

function RequestTable() {
  const rerender = React.useReducer(() => ({}), {})[1]

  const [columnFilters, setColumnFilters] = React.useState(
    []
  )
  const [globalFilter, setGlobalFilter] = React.useState('')
  const columnHelper = createColumnHelper();
  const [openHistory, setOpenHistory] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [disablePaginationButton, setDisablePaginationButton] = useState(false);
  const openShowHistoryModal = () => {
    setOpenHistory(!openHistory);
  };
  
  const columns = [
    
    columnHelper.accessor('SMVNo', {
      cell: info => info.getValue(),
      header: () => <span>SMV No.</span>,
      footer: info => info.column.id,
    }),
    columnHelper.accessor('name', {
      header: () => <span>Requester</span>,
      cell: info => info.renderValue(),
      footer: info => info.column.id,
    }),
    columnHelper.accessor('subject', {
      header: () => <span>Subject</span>,
      cell: info => info.renderValue(),
      footer: info => info.column.id,
    }),
    columnHelper.accessor('status', {
      header: () => <span>Status</span>,
      cell: info => info.renderValue(),
      footer: info => info.column.id,
    }),
    columnHelper.accessor('AnsweredBy', {
      header: () => <span>Answered By</span>,
      cell: (info) => {
        const replies = info.row.original?.replies;
        return (
          <>
            {replies?.[replies.length - 1]?.repliedBy}
          </>
        );
      },
      footer: info => info.column.id,
    }),
    columnHelper.accessor('Priority', {
      header: () => <span>Priority</span>,
      cell: info => info.renderValue(),
      footer: info => info.column.id,
    }),
    columnHelper.display({
      cell: (info) => info.getValue(),
      header: () => <span>History</span>,
      id: "history",
      cell: () => {
        return (
          <span className="w-9 h-9 border border-gray400 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer hover:border-black900 group hover:text-black900">
            <button>
              <Icon
                icon="mdi:eye"
                size={1}
                className="transition-all duration-150 group-hover:text-black900"
                onClick={() => openShowHistoryModal()}
              />
            </button>
          </span>
        )
      }
    }),
    columnHelper.accessor('Time', {
      header: () => <span>Time</span>,
        cell:  (info) => {
          const replies = info.row.original?.replies;
          return (
            <>
              {moment(replies?.[replies.length - 1]?.lastUpdateAt).format("DD-MM-YYYY")}
            </>
          );
        },
      footer: info => info.column.id,
    }),
  ]
  
  const getRequestData = async () => {
    try {
      setDisablePaginationButton(true);
      const response = await getAllPendingRequests({
        page: currentPage || 1,
        pageSize: pageSize || 10
      });
      if (response.data) {
        setRequestData(response.data.requests || response.data);
        setTotalPages(response.data.totalPages || 1);
      }
    } catch (error) {
      toastr.show(error?.response?.data?.message || "Something went wrong", "error");
    }
    setDisablePaginationButton(false);
  }

  useEffect(() => {
    getRequestData();
  }, [pageSize, currentPage]);

  const table = useReactTable({
    data: requestData,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  const handlePageChange = (direction) => {
    switch (direction) {
      case "prev":
        if (currentPage > 0) {
          setCurrentPage((prev) => prev - 1);
        }
        break;

      case "next":
        if (currentPage < totalPages) {
          setCurrentPage((prev) => prev + 1);
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <table className="w-full member-table overflow-auto">
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id} className='border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]'>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan} className='px-2 py-4'>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      </>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className='last:!border-b-0'>
          {table.getRowModel().rows.map(row => {
            return (
              <tr key={row.id} className='border-b border-stone-200 text-sm'>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td key={cell.id} className='text-center px-2 py-4'>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="flex items-center justify-end gap-2 mt-3">
        <button
          className="border rounded w-8 h-8 grid place-items-center"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <Icon icon="lucide:chevron-left" size={1} className="text-xl" />
        </button>
        <button
          className="border rounded w-8 h-8 grid place-items-center"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <Icon icon="lucide:chevron-right" size={1} className="text-xl" />
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount().toLocaleString()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded w-16 outline-none"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          className="outline-none p-1 border rounded "
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        </div>
        <div className="flex items-center justify-end gap-2 mt-3">
            <button
                className="border rounded w-8 h-8 grid place-items-center"
                onClick={() => handlePageChange("prev")}
                disabled={currentPage === 1 || disablePaginationButton}
            >
                <Icon icon="lucide:chevron-left" size={1} className="text-xl" />
            </button>
            <button
                className="border rounded w-8 h-8 grid place-items-center"
                onClick={() => handlePageChange("next")}
                disabled={currentPage === totalPages || disablePaginationButton}
            >
                <Icon icon="lucide:chevron-right" size={1} className="text-xl" />
            </button>
            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {currentPage} of {totalPages}
              </strong>
            </span>
            <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="outline-none p-1 border rounded "
            >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                </option>
                ))}
            </select>
        </div>

      <ShowHistoryOfRequest openHistory={openHistory} setOpenHistory={setOpenHistory} />
    </>
  )
}

export default RequestTable;