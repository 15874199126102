import React, { useEffect, useRef, useState } from "react";
import { Button } from "@material-tailwind/react";
import { Switch } from "@headlessui/react";
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { toastr } from "../../services/common.service";
import { s3Url } from "../../services/api.service";
import {
    changeBookStatus,
    deleteBook,
    getAllBooks,
    reorderBooks,
} from "../photo-banner/actionCreator";
import { useReactNavigator } from "../../utils/reactNavigator";
import { SevaListData } from "../Data/FackData";

const columnHelper = createColumnHelper();


const DraggableRow = ({ row, reorderSeva }) => {
    const [, dropRef] = useDrop({
        accept: "row",
        drop: (draggedRow) => reorderSeva(draggedRow.index, row.index),
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: "row",
    });


    return (
        <motion.tr
            ref={previewRef}
            initial={{ opacity: 1 }}
            animate={{ opacity: isDragging ? 0.5 : 1 }}
            exit={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
        >
            <td ref={dropRef} className="text-start w-[50px]">
                <button ref={dragRef} className="cursor-grab">
                    <Icon icon="grommet-icons:drag" className="text-xl" />
                </button>
            </td>
            {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </motion.tr>
    );
};

const SevaList = () => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const { navigate } = useReactNavigator();

    const getAllSevaDetail = async () => {
        try {
            setData(SevaListData)
        } catch (err) {
            console.error("Error getting album detail:", err);
        }
    };

    useEffect(() => {
        getAllSevaDetail();
    }, []);

    const reorderBook = (draggedRowIndex, targetRowIndex) => {
        data.splice(targetRowIndex, 0, data.splice(draggedRowIndex, 1)[0]);
        setData([...data]);
        const sevaIds = data.map((d) => d._id);
        reorderBooks({ sevaIds })
            .then(() => {
                getAllSevaDetail();
            })
            .catch((err) => {
                toastr.show("Something went wrong", "error");
            });
    };

    const changeVisibility = async (sevaId, status) => {
        try {
            const response = await changeBookStatus(sevaId, status);
            if (response) {
                toastr.show("Book status changed successfully!", "success");
                getAllSevaDetail();
            }
        } catch (err) {
            console.error("Error changing book status:", err);
        }
    };

    const columns = [

        columnHelper.accessor("imageURL", {
            cell: (info) => (
                <img
                    src={
                        info.getValue()
                            ? `${s3Url}${info.getValue()}`
                            : "/assets/image/no-thumbnail.png"
                    }
                    className="h-[50px] rounded-lg"
                />
            ),
            header: () => <span>Thumbnail</span>,
        }),
        columnHelper.accessor("seva", {
            cell: (info) => info.getValue(),
            header: () => <span>Seva</span>,
        }),
        columnHelper.accessor("enabled", {
            cell: (info) => (
                <Switch
                    // checked={info.getValue()}
                    className={`${info.getValue() ? "bg-blue-600" : "bg-gray-200"
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                    onClick={() =>
                        changeVisibility(info.row.original._id, !info.getValue())
                    }
                >
                    <span
                        className={`${info.getValue() ? "translate-x-6" : "translate-x-1"
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                </Switch>
            ),
            header: () => <span>Visibility</span>,
        }),
        columnHelper.display({
            header: () => <span className="text-center">Actions</span>,
            id: "actions",
            cell: (props) => {
                return (
                    <span className="flex items-center justify-center">
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group text-black900">
                            <button>
                                <Icon
                                    icon="ic:baseline-edit"
                                    className="text-2xl transition-all duration-150 text-black900"
                                    onClick={() => { }}
                                />
                            </button>
                        </span>
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
                            <button>
                                <Icon
                                    icon="material-symbols:delete"
                                    className="transition-all duration-150 text-2xl text-black900"
                                    onClick={() => { }}
                                />
                            </button>
                        </span>
                        <span className="w-9 h-9 border border-black900 rounded-xl transition-all duration-150 flex justify-center items-center mr-2 cursor-pointer group hover:text-black900">
                            <button
                                onClick={() => {
                                    navigate(`${props.row.original?._id}`, {
                                        data: props.row.original,
                                    });
                                }}
                            >
                                <Icon
                                    icon="ion:enter"
                                    className="text-2xl transition-all duration-150 text-black900"
                                />
                            </button>
                        </span>
                    </span>
                );
            },
        }),
    ];

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: (row) => row.id,
    });




    return (
        <div className="h-[calc(100vh_-_81px)] p-10 bg-[#F8F8F8] overflow-auto">
            <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
                <div className="text-[25px] text-[#212121]">Seva List</div>
                <Button
                    color="blue"
                    className="flex items-center min-w-[150px]"
                    onClick={() => { }}
                >
                    <Icon icon="ic:baseline-plus" className="mr-1 text-2xl" /> Add Book
                </Button>
            </div>

            <div className="bg-white w-full px-6 py-[30px] overflow-hidden rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
                <div className="overflow-auto">
                    <DndProvider backend={HTML5Backend}>
                        <table className="w-full">
                            <thead>
                                {table.getHeaderGroups().map((headerGroup) => {
                                    return (
                                        <tr className="border-b border-stone-200 text-sm bg-[#F2F2F2] text-[#535353]">
                                            <th className="text-left font-semibold p-2 min-w-[50px]"></th>
                                            {headerGroup.headers.map((header) => {
                                                return (
                                                    <th
                                                        key={header.id}
                                                        colSpan={header.colSpan}
                                                        className="text-left font-semibold p-2"
                                                    >
                                                        {header.isPlaceholder ? null : (
                                                            <div
                                                                {...{
                                                                    className: header.column.getCanSort()
                                                                        ? "cursor-pointer select-none"
                                                                        : "last:text-center",
                                                                }}
                                                            >
                                                                {flexRender(
                                                                    header.column.columnDef.header,
                                                                    header.getContext()
                                                                )}
                                                            </div>
                                                        )}
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    );
                                })}
                            </thead>
                            <tbody className="last:!border-b-0">
                                {table.getRowModel().rows.map((row) => (
                                    <DraggableRow
                                        key={row.id}
                                        row={row}
                                        reorderBook={reorderBook}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </DndProvider>
                </div>
            </div>
        </div>
    )
}

export default SevaList