import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import { Icon } from "@iconify/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import LoaderBar from "../Common/LoaderBar";
import { getUnindexedSocieties, updateSocietyIndex } from "./actionCreator";
import { toastr } from "../../services/common.service";
import clsx from "clsx";

const columnHelper = createColumnHelper();

const AttachSocietyIndex = () => {
  const [unindexedSocieties, setUnindexedSocieties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [disablePaginationButton, setDisablePaginationButton] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const inputRefs = useRef([]);

  const fetchUnindexedSocieties = useCallback(
    async (params = {}) => {
      setDisablePaginationButton(true);
      try {
        const response = await getUnindexedSocieties({
          currentPage,
          pageSize,
          queryParams: params,
        });

        if (response.data) {
          setDisablePaginationButton(true);
          setUnindexedSocieties(
            response.data.unindexedSociety || response.data
          );
          setTotalPages(response.data.totalPages || 1);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setDisablePaginationButton(false);
      }
    },
    [currentPage, pageSize]
  );

  useEffect(() => {
    const debouncedFetchResults = _.debounce((query) => {
      const queryParams = query ? { searchTerm: query } : {};
      fetchUnindexedSocieties(queryParams);
    }, 500);

    debouncedFetchResults(searchQuery);

    return () => {
      debouncedFetchResults.cancel();
    };
  }, [searchQuery, pageSize, currentPage]);

  const updateSociety = async (society) => {
    try {
      const updateObj = {
        society: society.society,
        societyIndex: society.societyIndex,
      };

      if (society.streetName) {
        updateObj.streetName = society.streetName;
      }
      if (society.city) {
        updateObj.city = society.city;
      }

      const response = await updateSocietyIndex(updateObj);

      if (response.data) {
        const _listIndex = _.cloneDeep(unindexedSocieties);
        const findSocietyIndex = _listIndex.findIndex(
          (item) =>
            item.society === society.society &&
            item.streetName === society.streetName &&
            item.city === society.city
        );
        _listIndex.splice(findSocietyIndex, 1);
        setUnindexedSocieties(_listIndex);
      }
      toastr.show("Society Index Added Successfully", "success");
    } catch (error) {
      console.log(error);
      toastr.show(
        error?.response?.data?.message || "Something went wrong",
        "error"
      );
    }
  };

  const columns = [
    columnHelper.accessor("society", {
      cell: (info) =>
        `${info.row.original.society || "N/A"}, ${
          info.row.original.streetName || "N/A"
        }, ${info.row.original.city || "N/A"}`,
      header: () => <span>Society</span>,
      size: 800,
    }),
    columnHelper.display({
      header: () => <span className="text-center">Index</span>,
      id: "unindexedSocietyIndex",
      cell: (props) => {
        const society = props.row.original;
        const index = props.row.index;

        return (
          <div className="flex items-center justify-center">
            <input
              placeholder="Add Index"
              type="number"
              className="w-[100px] rounded-[7px] border border-blue-gray-200 bg-transparent px-2 py-1 focus:outline-0 mr-2"
              value={society.societyIndex || ""}
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(value) => {
                const _listIndex = _.cloneDeep(unindexedSocieties);
                _listIndex[index].societyIndex = value?.target?.value;
                setUnindexedSocieties(_listIndex);
                setTimeout(() => inputRefs.current[index]?.focus(), 0);
              }}
            />
            <button
              className="bg-[#007AFF] text-white px-2 py-1 rounded-[5px]"
              onClick={() => updateSociety(society)}
            >
              Add
            </button>
          </div>
        );
      },
      size: 200,
    }),
  ];

  const table = useReactTable({
    data: unindexedSocieties,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handlePageChange = (direction) => {
    switch (direction) {
      case "prev":
        if (currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
        }
        break;

      case "next":
        if (currentPage < totalPages) {
          setCurrentPage((prev) => prev + 1);
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="h-[calc(100vh_-_58px)] p-6 xl:p-9 2xl:p-10 bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b">
          <div className="text-[25px] text-[#212121]">Attach Society Index</div>
        </div>

        <div className="bg-white h-[calc(100%_-_135px)] overflow-auto mb-3 w-full px-10 py-[30px] rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)]">
          <div className="flex items-center mb-3">
            <div className="w-56 relative">
              <input
                type="text"
                placeholder="Search societies..."
                className="bg-gray200 text-sm h-9 w-full text-black900 outline-none px-2.5 py-2 rounded-lg placeholder:text-stone-400 placeholder:text-sm caret-stone-400 placeholder:pl-0.5"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
              <Icon
                icon="iconamoon:search-light"
                className="absolute right-2 top-[50%] translate-y-[-50%] text-lg text-[#969696]"
              />
            </div>
          </div>

          <table className="w-full">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="text-sm bg-[#F2F2F2] text-[#535353]"
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="text-left font-semibold p-4 border border-gray-300"
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={clsx(
                            header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            "last:text-center"
                          )}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="border-b border-stone-200 text-sm hover:bg-gray-50"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className="px-4 py-4 border border-gray-200"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex items-center justify-end gap-2 mt-0 sticky p-2 px-4 bg-white border-t-2 border rounded-[10px]">
          <button
            className="border-blue-100 border-b-2 border rounded-lg w-7 h-7 grid place-items-center"
            onClick={() => handlePageChange("prev")}
            disabled={currentPage === 1 || disablePaginationButton}
          >
            <Icon icon="lucide:chevron-left" className="text-xl" />
          </button>
          <button
            className="border-blue-100 border-b-2 border rounded-lg w-7 h-7 grid place-items-center"
            onClick={() => handlePageChange("next")}
            disabled={currentPage === totalPages || disablePaginationButton}
          >
            <Icon icon="lucide:chevron-right" className="text-xl" />
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {currentPage} of {totalPages}
            </strong>
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="outline-none p-1 border-blue-100 border-b-2 border rounded-lg py-0.5"
          >
            {[10, 20, 30, 40, 50].map((page) => (
              <option key={page} value={page}>
                Show {page}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default AttachSocietyIndex;
