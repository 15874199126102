import React, { Fragment, useState } from "react";
import Icon from "@mdi/react";
import {
  Button,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import { mdiClose } from "@mdi/js";
import { Dialog, Transition, TransitionChild } from "@headlessui/react";
import moment from "moment-timezone";
import UploadItems from "../Common/UploadModal/UploadItems";
import DatePickerField from "../Common/Rsuite-Datepicker";

const DailyDarshanUpload = (props) => {
  const { open, setOpen, uploadFiles, selectedDate } = props;

  // Separate state for HD and compressed files
  const [hdFiles, setHdFiles] = useState([]);
  const [compressedFiles, setCompressedFiles] = useState([]);
  const [darshanDate, setDarshanDate] = useState(
    moment(selectedDate).startOf("day").toISOString()
  );

  // Reset function for clearing all files
  const resetFiles = () => {
    setHdFiles([]);
    setCompressedFiles([]);
  };

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        open={open}
        as="div"
        className="relative z-40"
        onClose={() => { }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-6xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex justify-between pb-4 border-b border-gray400">
                  <div className="text-lg font-semibold text-orange-600">
                    Upload Daily Darshan
                  </div>
                  <Icon
                    path={mdiClose}
                    size={1}
                    className="cursor-pointer text-[#D4D4D4] hover:text-[#A5A5A5] transition-all duration-150"
                    onClick={() => {
                      resetFiles();
                      setOpen(false);
                    }}
                  />
                </div>
                <div className="flex justify-center">
                  <div className="mt-6 w-full max-w-[300px]">
                    <DatePickerField
                      placeholder="Darshan Date"
                      value={
                        darshanDate
                          ? new Date(darshanDate)
                          : ""
                      }
                      onChange={(_date) =>
                        setDarshanDate(
                          moment(_date)
                            .startOf("day")
                            .toISOString()
                        )
                      }
                    />
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex h-[600px] gap-10">
                    <div className="w-1/2 flex flex-col h-full">
                      <div className="text-xl font-semibold text-center mb-5">
                        Compressed
                      </div>
                      <div className="flex-grow w-full overflow-auto">
                        <UploadItems
                          setFiles={setCompressedFiles}
                          files={compressedFiles}
                        />
                      </div>
                    </div>
                    <div className="w-1/2 flex flex-col h-full">
                      <div className="text-xl font-semibold text-center mb-5">
                        HD
                      </div>
                      <div className="flex-grow w-full overflow-auto">
                        <UploadItems
                          setFiles={setHdFiles}
                          files={hdFiles}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-right flex justify-end mt-6">
                    <div className="mr-5">
                      <Button
                        color="red"
                        variant="outlined"
                        className="capitalize text-base"
                        onClick={() => {
                          resetFiles();
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div>
                      <Button
                        className="capitalize text-base bg-orange-600"
                        onClick={() => {
                          const uploadBoth = async () => {
                            if (compressedFiles.length > 0) {
                              await uploadFiles(compressedFiles, darshanDate, "compress");
                            }
                            if (hdFiles.length > 0) {
                              await uploadFiles(hdFiles, darshanDate, "hd");
                            }
                            resetFiles();
                          };
                          uploadBoth();
                        }}
                      >
                        Upload
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DailyDarshanUpload;
