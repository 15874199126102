import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";

import { downloadSocietyPDF } from "./actionCreator";

export default function PdfComponent(props) {
  let componentRef = useRef();
  let printBtnRef = useRef();
  const { lastAPIParams } = props;
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    if (reportData.length > 0) {
      setTimeout(() => {
        printBtnRef.current.click();
      }, 100);
    }
    }, [reportData]);
  
  const myFunction = async () => {
    const queryParams = {
      ...lastAPIParams
    };
    const response = await downloadSocietyPDF(queryParams);
    if (response.data) {
      setReportData(response.data);
      console.log(response.data);
    }
  }

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  return (
    <>
      <div>
        <button style={{ display: "none" }} ref={printBtnRef} onClick={handlePrint}>Print</button>
        <div>
          <button
            className="bg-[#007AFF] text-white px-3 py-2 rounded-[5px] ml-2"
            onClick={() => myFunction()}
          >
            Download List
          </button>
        </div>

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          <div ref={componentRef}>
            <table className="w-full">
                <thead>
                    <tr>
                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1">Society Index</th>
                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1">Society</th>
                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1">Area</th>
                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-2 py-1">City</th>
                    </tr>
                </thead>
                <tbody>
                    {reportData.map((data) => (
                        <tr key={data._id} className='text-center p-2'>
                            <td className="p-2 border-b border-blue-gray-50">{data.societyIndex}</td>
                            <td className="p-2 border-b border-blue-gray-50">{data.society}</td>
                            <td className="p-2 border-b border-blue-gray-50">{data.streetName}</td>
                            <td className="p-2 border-b border-blue-gray-50">{data.city}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}