import React from "react";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import Textarea from "../Common/Input/Textarea";

const RequestAnswer = (props) => {
  const { setIsRequestAnswerModalOpen } = props;

  const closeRequestAnswerModel = () => {
    setIsRequestAnswerModalOpen(false);
  };

  return (
    <>
      <div className={`block fixed inset-0 z-[10000] bg-[#00000066]`}></div>
      <div
        className={`absolute max-h-[90%] pb-15 pt-5 transition-all duration-150 px-5 block max-w-[1000px] w-full  bg-white rounded-md left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] overflow-hidden z-[99999] modal`}
      >
        <div className=" fixed right-2.5 top-2.5">
          <button type="button" onClick={() => closeRequestAnswerModel()}>
            <Icon path={mdiClose} size={1} />
          </button>
        </div>

        <div className="p-2.5 custom-scroll">
          <div className="max-h-[calc(100vh_-_215px)]  overflow-auto">
            <div className="border border-stone-200 rounded-lg p-10 mt-5 mb-5 mr-2 min-h-[520px] flex flex-col justify-between">
              <div className="max-w-[700px] pb-5">
                <div className="text-sm text-right">08/04/2024 at 11:00</div>
              </div>
              <div className="grid grid-cols-3 gap-x-10 gap-y-5">
                <div className="max-w-[700px] pb-5">
                  <label>Requester : </label>
                  <label className="ml-2">Hari</label>
                </div>
                <div className="max-w-[700px] pb-5">
                  <label>Category : </label>
                  <label className="ml-2">Profile Pic</label>
                </div>
                <div className="max-w-[700px] pb-5">
                  <label>Status : </label>
                  <label className="ml-2">
                    <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                      Pending
                    </span>
                  </label>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-x-10 gap-y-5 mt-10">
                <div className="max-w-[700px] pb-10">
                  <label>Subject : </label>
                  <div>Change The Profile Pic</div>
                </div>
                <div className="max-w-[700px] pb-10">
                  <label></label>
                  <div></div>
                </div>
                <div className="max-w-[700px] pb-10">
                  <label>Priority : </label>
                  <span className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                    High
                  </span>
                </div>
              </div>

              <div className="max-w-[700px] pb-10 mt-10">
                <label>Details : </label>
                <div>Change The Profile Pic</div>
              </div>

              <div className="max-w-[700px] pb-10 mt-10">
                <label>Answer : </label>
                <div className="mt-5">
                  <Textarea label="Description" />
                </div>
              </div>

              <div className="max-w-[700px] pb-10 mt-10">
                <label>Attachments : </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestAnswer;
