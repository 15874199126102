import React, { useState } from "react";
import { Typography } from "@material-tailwind/react";
import RequestAnswer from './RequestAnswer'
import RequestTable from "./RequestTable";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Button } from "@material-tailwind/react";
import CreateSubject from "./CreateSubject";

const imglist = [
  {
    id: 1,
    path: "/assets/image/admin.jpg",
  },
];

const RequestData = () => {
  const [isRequestAnswerModalOpen, setIsRequestAnswerModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const openCreateLinkModal = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="h-[calc(100vh_-_81px)]  p-6 xl:p-9 2xl:p-[50px] bg-[#F8F8F8] overflow-auto">
        <div className="flex items-center justify-between pb-[15px] mb-[20px] border-b-[#D7D7D7] border-b border-solid">
          <div className="text-[25px] text-[#212121]">Request</div>
          <Button
            color="blue"
            className="flex items-center min-w-[150px]"
            onClick={() => openCreateLinkModal()}
          >
            <Icon icon={"ic:baseline-plus"} size={1} className="mr-1" /> Create Subject
          </Button>
        </div>
        <div className="bg-white w-full p-7 rounded-[10px] shadow-[1px_-1px_10px_0px_rgba(0,0,0,0.10)] ">
          <div className="p-2 overflow-auto">
            <RequestTable />
          </div>
        </div>
      </div>

      {isRequestAnswerModalOpen && <RequestAnswer setIsRequestAnswerModalOpen={setIsRequestAnswerModalOpen} />}

      <CreateSubject
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default RequestData;
