import _ from "lodash";
import { api } from "../../services/api.service";

export const getAllPendingRequests = async (data) => {
  const response = await api.post("admin/request/pending-list", data);
  return response;
}

export const changeBookStatus = async (bookId, status) => {
  const response = await api.post("books/change-status", { _id: bookId, enabled: status });
  return response;
}

export const reorderBooks = async (data) => {
  const response = await api.post("books/reorder", data);
  return response;
}
