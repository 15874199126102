import React from "react";
import { useRoutes } from "react-router-dom";
import {
    AttachSocietyIndex,
    SocietyList
} from "../../../Components";

export default function AddressRouter() {
  const AddressRoute = [
    {
      path: "society-list/*",
      element: (
        <>
          <SocietyList />
        </>
      ),
    },
    {
      path: "attach-society-index/*",
      element: (
        <>
          <AttachSocietyIndex />
        </>
      ),
    },
  ];
  const AddressRouter = useRoutes(AddressRoute);
  return AddressRouter;
}
