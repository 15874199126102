import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState, useCallback, useEffect } from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import EventUpload from "./UploadContent";

const ItemTypes = {
  IMAGE: 'image'
}

const DraggableImage = ({ preview, file, index, moveImage, handleRemoveFile }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.IMAGE,
    item: () => ({ index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE,
    hover: (draggedItem, monitor) => {
      if (!monitor.isOver({ shallow: true })) return;

      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const container = document.querySelector('.upload-items-container');
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const containerBoundingRect = container?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (container) {
        if (hoverClientY < hoverMiddleY && hoverBoundingRect.top < containerBoundingRect.top + 20) {
          container.scrollBy({ top: -15, behavior: 'smooth' });
        }
        if (hoverClientY > hoverMiddleY && hoverBoundingRect.bottom > containerBoundingRect.bottom - 20) {
          container.scrollBy({ top: 15, behavior: 'smooth' });
        }
      }

      moveImage(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });

  const ref = React.useRef(null);
  const dragDropRef = drag(drop(ref));


  return (
    <div
      ref={dragDropRef}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className="flex items-center gap-4 bg-gray-50 p-2 rounded-lg cursor-move"
    >
      <div>
        <img
          src={preview}
          className="block rounded-lg object-contain object-center h-[80px] w-[60px]"
        />
      </div>
      <div className="flex-1">
        <p className="text-sm text-gray-700">{file.name}</p>
      </div>
      <button
        onClick={() => handleRemoveFile(index)}
        className="p-2 hover:bg-gray-200 rounded-full"
      >
        <Icon icon="ph:trash-fill" className="text-gray-600" />
      </button>
    </div>
  );
};

const UploadItems = (props) => {
  const { files, setFiles } = props;
  const [imagePreviews, setImagePreviews] = useState([]);

  const moveImage = useCallback((dragIndex, hoverIndex) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      const draggedFile = newFiles[dragIndex];
      newFiles.splice(dragIndex, 1);
      newFiles.splice(hoverIndex, 0, draggedFile);
      return newFiles;
    });

    setImagePreviews((prevPreviews) => {
      const newPreviews = [...prevPreviews];
      const draggedPreview = newPreviews[dragIndex];
      newPreviews.splice(dragIndex, 1);
      newPreviews.splice(hoverIndex, 0, draggedPreview);
      return newPreviews;
    });
  }, []);

  // ... बाकी handleFileChange और handleRemoveFile फंक्शंस यथावत रहेंगे ...
  const handleFileChange = (_files) => {
    const selectedFiles = _files;
    setFiles([...files, ...selectedFiles]);

    const previews = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        previews.push(e.target.result);
        if (previews.length === selectedFiles.length) {
          setImagePreviews([...imagePreviews, ...previews]);
        }
      };
      reader.readAsDataURL(selectedFiles[i]);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...files];
    const updatedPreviews = [...imagePreviews];
    updatedFiles.splice(index, 1);
    updatedPreviews.splice(index, 1);
    setFiles(updatedFiles);
    setImagePreviews(updatedPreviews);
  };

  useEffect(() => {
    return () => {
      setFiles([]);
      setImagePreviews([]);
    };
  }, [setFiles]);


  return (
    <DndProvider backend={HTML5Backend}>
      <div className="h-full">
        {imagePreviews.length > 0 && files.length > 0 && ( // Added files.length check
          <div className="flex flex-col gap-4">
            {imagePreviews.map((preview, index) => (
              files[index] ? (
                <DraggableImage
                  key={`${files[index].name}-${index}`}
                  preview={preview}
                  file={files[index]}
                  index={index}
                  moveImage={moveImage}
                  handleRemoveFile={handleRemoveFile}
                />
              ) : null
            ))}
          </div>
        )}
        {(imagePreviews.length === 0 || files.length === 0) && (
          <EventUpload handleFileChange={handleFileChange} multiple={true} />
        )}
      </div>
    </DndProvider>
  );
};

export default UploadItems;