export const FILTER_TYPES = {
    address: "Address",
    village: "Village",
    groupNos: "Group No",
    sabhas: "Sabha",
    saints: "Santo",
    isActive: "Active",
    icardGiven: "I-card",
    gender: "gender",
    blocked: "blocked",
    allHOD:"All HOD",
    allLeaders: "All Leaders",
    allHOL: "All HOL",
    searchTerm: "Search",
    SMVNo: "SMVNo",
    minAge: "Min Age",
    maxAge: "Max Age",
    birthMonth: "Birthday Month",
    department: "Department",
    minSocietyIndex: "Min Society Index",
    maxSocietyIndex: "Max Society Index",
}